const d = {
  defined: (t, e) => {
    if (t === void 0)
      throw e instanceof Error ? e : new Error(`'${e}' is undefined`);
  },
  array: {
    notEmpty: (t, e) => {
      if (!t || !t.length)
        throw e instanceof Error ? e : new Error(`Array '${e}' is ${t && "empty"}`);
    }
  },
  map: {
    notEmpty: (t, e) => {
      if (!t || !t.size)
        throw e instanceof Error ? e : new Error(`Map '${e}' is ${t && "empty"}`);
    }
  },
  promise: {
    resolves: async (t, e = "promise threw an error", r = !0) => {
      const n = () => {
        const i = e instanceof Error ? e : new Error(e);
        return Error.captureStackTrace(i, d.promise.resolves), i;
      }, o = r ? n() : void 0;
      try {
        return await t;
      } catch (i) {
        const c = o || n();
        throw c.cause = i, c;
      }
    }
  },
  ok: (t, e) => {
    if (!t)
      throw e instanceof Error ? e : new Error(e);
  }
}, p = (t) => {
  const e = new Date(t), r = e.getFullYear();
  let n = e.getDate(), o = e.getMonth() + 1;
  return n < 10 && (n = `0${n}`), o < 10 && (o = `0${o}`), `${n}/${o}/${r}`;
}, h = (t) => {
  const e = new Date(t);
  let r, n, o;
  return r = e.getHours(), n = e.getMinutes(), o = e.getSeconds(), o <= 9 && (o = `0${o}`), n <= 9 && (n = `0${n}`), r <= 9 && (r = `0${r}`), `${r}:${n}:${o}`;
}, j = (t) => `${p(t)}, ${h(t)}`, x = (t) => {
  const e = parseFloat(t.toString());
  return Number.isNaN(e) ? null : e * 60 * 60 * 1e3;
}, O = (t) => t || "N/A", R = (t) => t.length > 7 ? `${t.substring(0, 7)}...` : t, A = (t, e = 5, r = 3) => t ? t.length <= e + r ? t : `${t.substring(0, e)}...${t.substring(t.length - r)}` : "";
var u, y = new Uint8Array(16);
function $() {
  if (!u && (u = typeof crypto < "u" && crypto.getRandomValues && crypto.getRandomValues.bind(crypto) || typeof msCrypto < "u" && typeof msCrypto.getRandomValues == "function" && msCrypto.getRandomValues.bind(msCrypto), !u))
    throw new Error("crypto.getRandomValues() not supported. See https://github.com/uuidjs/uuid#getrandomvalues-not-supported");
  return u(y);
}
const w = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
function E(t) {
  return typeof t == "string" && w.test(t);
}
var s = [];
for (var a = 0; a < 256; ++a)
  s.push((a + 256).toString(16).substr(1));
function m(t) {
  var e = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, r = (s[t[e + 0]] + s[t[e + 1]] + s[t[e + 2]] + s[t[e + 3]] + "-" + s[t[e + 4]] + s[t[e + 5]] + "-" + s[t[e + 6]] + s[t[e + 7]] + "-" + s[t[e + 8]] + s[t[e + 9]] + "-" + s[t[e + 10]] + s[t[e + 11]] + s[t[e + 12]] + s[t[e + 13]] + s[t[e + 14]] + s[t[e + 15]]).toLowerCase();
  if (!E(r))
    throw TypeError("Stringified UUID is invalid");
  return r;
}
function b(t, e, r) {
  t = t || {};
  var n = t.random || (t.rng || $)();
  if (n[6] = n[6] & 15 | 64, n[8] = n[8] & 63 | 128, e) {
    r = r || 0;
    for (var o = 0; o < 16; ++o)
      e[r + o] = n[o];
    return e;
  }
  return m(n);
}
const M = () => b(), N = (t, e) => e ? t.findIndex((r) => r.id === e) : -1, S = (t) => {
  var e;
  return Number.isInteger(Number(t)) ? 0 : (e = t.toString().split(".")[1]) == null ? void 0 : e.length;
}, D = (t) => Math.sign(t) === 0, U = (t) => Math.sign(t) === -1, C = (t = 1e7) => Math.floor(Math.random() * t), I = (t, e) => e.split(".").reduce((r, n) => typeof r > "u" || r === null ? r : r[n], t), v = (t, e) => {
  try {
    let r, n = t;
    return Object.keys(e).forEach((o) => {
      let i = e[o] !== void 0 && e[o] !== null ? e[o] : "";
      typeof i == "string" && i.indexOf('"') > -1 && (i = i.replace(/"/g, '\\"')), r = new RegExp(`{{${o}}}`, "g"), n = n.replace(r, i);
    }), n;
  } catch {
    return "";
  }
}, f = (t) => !!(t && typeof t == "object" && t.constructor.name === "Object"), T = (t) => f(t) && Object.keys(t).length === 0, g = (t, ...e) => {
  if (!e.length)
    return t;
  const r = e.shift();
  return f(t) && f(r) && Object.keys(r).forEach((n) => {
    f(r[n]) ? (t[n] || Object.assign(t, { [n]: {} }), g(t[n], r[n])) : Object.assign(t, { [n]: r[n] });
  }), g(t, ...e);
}, z = async (t) => {
  try {
    return [await t, null];
  } catch (e) {
    return [null, e];
  }
}, V = (t) => Array.isArray(t) ? t : [t], B = (t, e = !1) => typeof t == "object" ? e ? JSON.stringify(t, null, 2) : JSON.stringify(t) : t, F = (t) => {
  for (let e = t.length - 1; e > 0; e--) {
    const r = Math.floor(Math.random() * (e + 1));
    [t[e], t[r]] = [t[r], t[e]];
  }
  return t;
}, H = (t, e) => Object.prototype.hasOwnProperty.call(t, e), Z = (t) => t.charAt(0).toUpperCase() + t.slice(1);
function L(t) {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(t);
}
const J = (t, e, r = {}) => {
  let n = t;
  const o = Object.keys(e);
  o.length && (n += "?"), o.forEach((c) => {
    const l = e[c];
    (!/.*\{\{.+}}.*/.test(l) || r[l.replace(/[{}]/g, "")]) && (n += `${c}=${l}&`);
  }), (n.slice(-1) === "?" || n.slice(-1) === "&") && (n = n.slice(0, -1));
  const i = v(n, r);
  return new URL(i);
};
export {
  S as afterDecimal,
  d as assert,
  J as buildDynamicURL,
  Z as capitalize,
  v as evaluate,
  N as findIndexById,
  O as formatBalance,
  M as generateId,
  C as generateRandomNumber,
  h as get24Time,
  p as getDate,
  j as getFullTime,
  x as getMsFromHrs,
  I as getPropertyPath,
  H as hop,
  T as isEmptyObject,
  U as isNegative,
  f as isObject,
  D as isZero,
  g as mergeDeep,
  z as promiseHandler,
  A as shortenAddress,
  R as shortenBalance,
  F as shuffleArray,
  B as stringifyData,
  L as validateEmail,
  V as valueToArray
};
