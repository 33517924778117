var Ti = [];
for (var ti = 0; ti < 256; ++ti)
  Ti.push((ti + 256).toString(16).substr(1));
const H = (t) => !!(t && typeof t == "object" && t.constructor.name === "Object"), ai = (t, ...n) => {
  if (!n.length)
    return t;
  const s = n.shift();
  return H(t) && H(s) && Object.keys(s).forEach((c) => {
    H(s[c]) ? (t[c] || Object.assign(t, { [c]: {} }), ai(t[c], s[c])) : Object.assign(t, { [c]: s[c] });
  }), ai(t, ...n);
}, xi = async (t) => {
  try {
    return [await t, null];
  } catch (n) {
    return [null, n];
  }
}, Ui = (t) => {
  var n;
  (n = window.open(t, "_blank")) == null || n.focus();
}, Pi = (t) => {
  const n = new RegExp(`[?&]${t}=([^&#]*)`).exec(window.location.href);
  return n === null ? null : decodeURI(n[1]) || "";
}, Mi = (t, n) => {
  if (t && n) {
    const s = new URL(window.location.href);
    t = encodeURIComponent(t), n = encodeURIComponent(n), s.searchParams.set(t, n), window.history.pushState("", "", s.toString());
  }
}, ji = (...t) => {
  const n = new URL(window.location.href);
  t.forEach((s) => n.searchParams.delete(s)), window.history.pushState("", "", n.toString());
}, _i = (t) => {
  const n = document.cookie, s = `${t}=`;
  let c = n.indexOf(`; ${s}`), v;
  if (c === -1) {
    if (c = n.indexOf(s), c !== 0)
      return null;
  } else
    c += 2;
  v = n.indexOf(";", c), v === -1 && (v = n.length);
  const g = decodeURI(n.substring(c + s.length, v));
  try {
    return JSON.parse(g);
  } catch {
    return g;
  }
}, Ii = (t, n, s) => {
  let c;
  if (s) {
    const g = /* @__PURE__ */ new Date();
    g.setTime(g.getTime() + s * 24 * 60 * 60 * 1e3), c = `; expires=${g.toUTCString()}`;
  } else
    c = "";
  let v = n;
  if (H(v)) {
    const g = _i(t) || {};
    v = ai(g, v);
  }
  document.cookie = `${t}=${JSON.stringify(v)}${c}; path=/`;
}, zi = async (t) => {
  const n = navigator == null ? void 0 : navigator.clipboard, [, s] = await xi(n == null ? void 0 : n.writeText(t));
  return !s;
}, qi = (t, n, s) => {
  console.log(`%c ${t} v${n}`, `color: ${s || "#ff98f9"};  font-size: large`);
};
function Ai(t) {
  return t[1] !== void 0;
}
const Bi = (t, n = {}) => {
  const s = Object.entries(n).filter(Ai), c = t.includes("?") ? "&" : "?";
  window.location.replace(
    `${t}${s.length > 0 ? `${c}${new URLSearchParams(s).toString()}` : ""}`
  );
}, ki = (t) => new Promise((n, s) => {
  const c = document.createElement("script");
  c.src = t, c.async = !0, c.onload = () => {
    n();
  }, c.onerror = () => s(new Error("Script failed to load")), document.head.appendChild(c);
}), Li = async (t) => await ki(`https://www.google.com/recaptcha/api.js?render=${t}`), Di = (t) => new Promise((n, s) => {
  if (window.gtag = window.gtag || function(...c) {
    (window.dataLayer = window.dataLayer || []).push(c);
  }, window.dataLayer) {
    n();
    return;
  }
  xi(ki(`https://www.googletagmanager.com/gtag/js?id=${t}`)).then(([, c]) => {
    c ? s(c) : (window.gtag("js", /* @__PURE__ */ new Date()), window.gtag("config", t), n());
  }).catch(s);
}), Vi = (t) => {
  const n = t ? document.getElementById(t) : null;
  n == null || n.scrollIntoView({ behavior: "smooth" });
}, Hi = (t, n) => localStorage ? (localStorage.setObjectHash(t, JSON.stringify(n)), !0) : !1, Gi = (t) => {
  if (localStorage) {
    const n = localStorage.getObjectHash(t);
    try {
      return JSON.parse(Object.values(n).join(""));
    } catch {
      return n;
    }
  }
  return null;
};
function vi(t) {
  return btoa(
    encodeURIComponent(t).replace(/%([0-9A-F]{2})/g, function(n, s) {
      return String.fromCharCode(+`0x${s}`);
    })
  );
}
function ri(t) {
  return decodeURIComponent(
    Array.prototype.map.call(atob(t), function(n) {
      return `%${n.charCodeAt(0).toString(16)}`;
    }).join("")
  );
}
typeof Storage < "u" && (Storage.prototype.setObjectHash = function(t, n) {
  const s = {};
  Object.keys(n).map(function(c) {
    return s[c] = vi(n[c]), s[c];
  }), this.setItem(t, vi(JSON.stringify(s)));
}, Storage.prototype.getObjectHash = function(t) {
  const n = this.getItem(t);
  return n ? ri(n) && JSON.parse(ri(n), function(s) {
    return s ? ri(this[s]) : this[s];
  }) : null;
});
const Fi = (...t) => [...t].join(" "), Wi = (t, n) => {
  let s;
  return /^#([A-Fa-f\d]{3}){1,2}$/.test(t) ? (s = t.substring(1).split(""), s.length === 3 && (s = [s[0], s[0], s[1], s[1], s[2], s[2]]), s = `0x${s.join("")}`, `rgba(${[s / 65536 & 255, s >> 8 & 255, s & 255].join(",")}, ${n})`) : "";
};
var Ri = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, U = {}, Ni = {
  get exports() {
    return U;
  },
  set exports(t) {
    U = t;
  }
};
(function(t, n) {
  (function(s, c) {
    var v = "1.0.35", g = "", G = "?", F = "function", P = "undefined", W = "object", M = "string", ni = "major", e = "model", r = "name", i = "type", o = "vendor", a = "version", x = "architecture", A = "console", d = "mobile", w = "tablet", h = "smarttv", E = "wearable", Y = "embedded", J = 350, j = "Amazon", R = "Apple", si = "ASUS", ci = "BlackBerry", T = "Browser", I = "Chrome", yi = "Edge", z = "Firefox", q = "Google", bi = "Huawei", $ = "LG", Z = "Microsoft", li = "Motorola", B = "Opera", X = "Samsung", wi = "Sharp", L = "Sony", K = "Xiaomi", Q = "Zebra", di = "Facebook", ui = "Chromium OS", pi = "Mac OS", Si = function(u, m) {
      var l = {};
      for (var f in u)
        m[f] && m[f].length % 2 === 0 ? l[f] = m[f].concat(u[f]) : l[f] = u[f];
      return l;
    }, D = function(u) {
      for (var m = {}, l = 0; l < u.length; l++)
        m[u[l].toUpperCase()] = u[l];
      return m;
    }, mi = function(u, m) {
      return typeof u === M ? N(m).indexOf(N(u)) !== -1 : !1;
    }, N = function(u) {
      return u.toLowerCase();
    }, Oi = function(u) {
      return typeof u === M ? u.replace(/[^\d\.]/g, g).split(".")[0] : c;
    }, ii = function(u, m) {
      if (typeof u === M)
        return u = u.replace(/^\s\s*/, g), typeof m === P ? u : u.substring(0, J);
    }, C = function(u, m) {
      for (var l = 0, f, O, y, p, b, S; l < m.length && !b; ) {
        var oi = m[l], hi = m[l + 1];
        for (f = O = 0; f < oi.length && !b && oi[f]; )
          if (b = oi[f++].exec(u), b)
            for (y = 0; y < hi.length; y++)
              S = b[++O], p = hi[y], typeof p === W && p.length > 0 ? p.length === 2 ? typeof p[1] == F ? this[p[0]] = p[1].call(this, S) : this[p[0]] = p[1] : p.length === 3 ? typeof p[1] === F && !(p[1].exec && p[1].test) ? this[p[0]] = S ? p[1].call(this, S, p[2]) : c : this[p[0]] = S ? S.replace(p[1], p[2]) : c : p.length === 4 && (this[p[0]] = S ? p[3].call(this, S.replace(p[1], p[2])) : c) : this[p] = S || c;
        l += 2;
      }
    }, ei = function(u, m) {
      for (var l in m)
        if (typeof m[l] === W && m[l].length > 0) {
          for (var f = 0; f < m[l].length; f++)
            if (mi(m[l][f], u))
              return l === G ? c : l;
        } else if (mi(m[l], u))
          return l === G ? c : l;
      return u;
    }, Ei = {
      "1.0": "/8",
      "1.2": "/1",
      "1.3": "/3",
      "2.0": "/412",
      "2.0.2": "/416",
      "2.0.3": "/417",
      "2.0.4": "/419",
      "?": "/"
    }, fi = {
      ME: "4.90",
      "NT 3.11": "NT3.51",
      "NT 4.0": "NT4.0",
      2e3: "NT 5.0",
      XP: ["NT 5.1", "NT 5.2"],
      Vista: "NT 6.0",
      7: "NT 6.1",
      8: "NT 6.2",
      "8.1": "NT 6.3",
      10: ["NT 6.4", "NT 10.0"],
      RT: "ARM"
    }, gi = {
      browser: [
        [
          /\b(?:crmo|crios)\/([\w\.]+)/i
          // Chrome for Android/iOS
        ],
        [a, [r, "Chrome"]],
        [
          /edg(?:e|ios|a)?\/([\w\.]+)/i
          // Microsoft Edge
        ],
        [a, [r, "Edge"]],
        [
          // Presto based
          /(opera mini)\/([-\w\.]+)/i,
          // Opera Mini
          /(opera [mobiletab]{3,6})\b.+version\/([-\w\.]+)/i,
          // Opera Mobi/Tablet
          /(opera)(?:.+version\/|[\/ ]+)([\w\.]+)/i
          // Opera
        ],
        [r, a],
        [
          /opios[\/ ]+([\w\.]+)/i
          // Opera mini on iphone >= 8.0
        ],
        [a, [r, B + " Mini"]],
        [
          /\bopr\/([\w\.]+)/i
          // Opera Webkit
        ],
        [a, [r, B]],
        [
          // Mixed
          /(kindle)\/([\w\.]+)/i,
          // Kindle
          /(lunascape|maxthon|netfront|jasmine|blazer)[\/ ]?([\w\.]*)/i,
          // Lunascape/Maxthon/Netfront/Jasmine/Blazer
          // Trident based
          /(avant |iemobile|slim)(?:browser)?[\/ ]?([\w\.]*)/i,
          // Avant/IEMobile/SlimBrowser
          /(ba?idubrowser)[\/ ]?([\w\.]+)/i,
          // Baidu Browser
          /(?:ms|\()(ie) ([\w\.]+)/i,
          // Internet Explorer
          // Webkit/KHTML based                                               // Flock/RockMelt/Midori/Epiphany/Silk/Skyfire/Bolt/Iron/Iridium/PhantomJS/Bowser/QupZilla/Falkon
          /(flock|rockmelt|midori|epiphany|silk|skyfire|bolt|iron|vivaldi|iridium|phantomjs|bowser|quark|qupzilla|falkon|rekonq|puffin|brave|whale(?!.+naver)|qqbrowserlite|qq|duckduckgo)\/([-\w\.]+)/i,
          // Rekonq/Puffin/Brave/Whale/QQBrowserLite/QQ, aka ShouQ
          /(heytap|ovi)browser\/([\d\.]+)/i,
          // Heytap/Ovi
          /(weibo)__([\d\.]+)/i
          // Weibo
        ],
        [r, a],
        [
          /(?:\buc? ?browser|(?:juc.+)ucweb)[\/ ]?([\w\.]+)/i
          // UCBrowser
        ],
        [a, [r, "UC" + T]],
        [
          /microm.+\bqbcore\/([\w\.]+)/i,
          // WeChat Desktop for Windows Built-in Browser
          /\bqbcore\/([\w\.]+).+microm/i
        ],
        [a, [r, "WeChat(Win) Desktop"]],
        [
          /micromessenger\/([\w\.]+)/i
          // WeChat
        ],
        [a, [r, "WeChat"]],
        [
          /konqueror\/([\w\.]+)/i
          // Konqueror
        ],
        [a, [r, "Konqueror"]],
        [
          /trident.+rv[: ]([\w\.]{1,9})\b.+like gecko/i
          // IE11
        ],
        [a, [r, "IE"]],
        [
          /ya(?:search)?browser\/([\w\.]+)/i
          // Yandex
        ],
        [a, [r, "Yandex"]],
        [
          /(avast|avg)\/([\w\.]+)/i
          // Avast/AVG Secure Browser
        ],
        [[r, /(.+)/, "$1 Secure " + T], a],
        [
          /\bfocus\/([\w\.]+)/i
          // Firefox Focus
        ],
        [a, [r, z + " Focus"]],
        [
          /\bopt\/([\w\.]+)/i
          // Opera Touch
        ],
        [a, [r, B + " Touch"]],
        [
          /coc_coc\w+\/([\w\.]+)/i
          // Coc Coc Browser
        ],
        [a, [r, "Coc Coc"]],
        [
          /dolfin\/([\w\.]+)/i
          // Dolphin
        ],
        [a, [r, "Dolphin"]],
        [
          /coast\/([\w\.]+)/i
          // Opera Coast
        ],
        [a, [r, B + " Coast"]],
        [
          /miuibrowser\/([\w\.]+)/i
          // MIUI Browser
        ],
        [a, [r, "MIUI " + T]],
        [
          /fxios\/([-\w\.]+)/i
          // Firefox for iOS
        ],
        [a, [r, z]],
        [
          /\bqihu|(qi?ho?o?|360)browser/i
          // 360
        ],
        [[r, "360 " + T]],
        [
          /(oculus|samsung|sailfish|huawei)browser\/([\w\.]+)/i
        ],
        [[r, /(.+)/, "$1 " + T], a],
        [
          // Oculus/Samsung/Sailfish/Huawei Browser
          /(comodo_dragon)\/([\w\.]+)/i
          // Comodo Dragon
        ],
        [[r, /_/g, " "], a],
        [
          /(electron)\/([\w\.]+) safari/i,
          // Electron-based App
          /(tesla)(?: qtcarbrowser|\/(20\d\d\.[-\w\.]+))/i,
          // Tesla
          /m?(qqbrowser|baiduboxapp|2345Explorer)[\/ ]?([\w\.]+)/i
          // QQBrowser/Baidu App/2345 Browser
        ],
        [r, a],
        [
          /(metasr)[\/ ]?([\w\.]+)/i,
          // SouGouBrowser
          /(lbbrowser)/i,
          // LieBao Browser
          /\[(linkedin)app\]/i
          // LinkedIn App for iOS & Android
        ],
        [r],
        [
          // WebView
          /((?:fban\/fbios|fb_iab\/fb4a)(?!.+fbav)|;fbav\/([\w\.]+);)/i
          // Facebook App for iOS & Android
        ],
        [[r, di], a],
        [
          /(kakao(?:talk|story))[\/ ]([\w\.]+)/i,
          // Kakao App
          /(naver)\(.*?(\d+\.[\w\.]+).*\)/i,
          // Naver InApp
          /safari (line)\/([\w\.]+)/i,
          // Line App for iOS
          /\b(line)\/([\w\.]+)\/iab/i,
          // Line App for Android
          /(chromium|instagram)[\/ ]([-\w\.]+)/i
          // Chromium/Instagram
        ],
        [r, a],
        [
          /\bgsa\/([\w\.]+) .*safari\//i
          // Google Search Appliance on iOS
        ],
        [a, [r, "GSA"]],
        [
          /musical_ly(?:.+app_?version\/|_)([\w\.]+)/i
          // TikTok
        ],
        [a, [r, "TikTok"]],
        [
          /headlesschrome(?:\/([\w\.]+)| )/i
          // Chrome Headless
        ],
        [a, [r, I + " Headless"]],
        [
          / wv\).+(chrome)\/([\w\.]+)/i
          // Chrome WebView
        ],
        [[r, I + " WebView"], a],
        [
          /droid.+ version\/([\w\.]+)\b.+(?:mobile safari|safari)/i
          // Android Browser
        ],
        [a, [r, "Android " + T]],
        [
          /(chrome|omniweb|arora|[tizenoka]{5} ?browser)\/v?([\w\.]+)/i
          // Chrome/OmniWeb/Arora/Tizen/Nokia
        ],
        [r, a],
        [
          /version\/([\w\.\,]+) .*mobile\/\w+ (safari)/i
          // Mobile Safari
        ],
        [a, [r, "Mobile Safari"]],
        [
          /version\/([\w(\.|\,)]+) .*(mobile ?safari|safari)/i
          // Safari & Safari Mobile
        ],
        [a, r],
        [
          /webkit.+?(mobile ?safari|safari)(\/[\w\.]+)/i
          // Safari < 3.0
        ],
        [r, [a, ei, Ei]],
        [
          /(webkit|khtml)\/([\w\.]+)/i
        ],
        [r, a],
        [
          // Gecko based
          /(navigator|netscape\d?)\/([-\w\.]+)/i
          // Netscape
        ],
        [[r, "Netscape"], a],
        [
          /mobile vr; rv:([\w\.]+)\).+firefox/i
          // Firefox Reality
        ],
        [a, [r, z + " Reality"]],
        [
          /ekiohf.+(flow)\/([\w\.]+)/i,
          // Flow
          /(swiftfox)/i,
          // Swiftfox
          /(icedragon|iceweasel|camino|chimera|fennec|maemo browser|minimo|conkeror|klar)[\/ ]?([\w\.\+]+)/i,
          // IceDragon/Iceweasel/Camino/Chimera/Fennec/Maemo/Minimo/Conkeror/Klar
          /(seamonkey|k-meleon|icecat|iceape|firebird|phoenix|palemoon|basilisk|waterfox)\/([-\w\.]+)$/i,
          // Firefox/SeaMonkey/K-Meleon/IceCat/IceApe/Firebird/Phoenix
          /(firefox)\/([\w\.]+)/i,
          // Other Firefox-based
          /(mozilla)\/([\w\.]+) .+rv\:.+gecko\/\d+/i,
          // Mozilla
          // Other
          /(polaris|lynx|dillo|icab|doris|amaya|w3m|netsurf|sleipnir|obigo|mosaic|(?:go|ice|up)[\. ]?browser)[-\/ ]?v?([\w\.]+)/i,
          // Polaris/Lynx/Dillo/iCab/Doris/Amaya/w3m/NetSurf/Sleipnir/Obigo/Mosaic/Go/ICE/UP.Browser
          /(links) \(([\w\.]+)/i,
          // Links
          /panasonic;(viera)/i
          // Panasonic Viera
        ],
        [r, a],
        [
          /(cobalt)\/([\w\.]+)/i
          // Cobalt
        ],
        [r, [a, /master.|lts./, ""]]
      ],
      cpu: [
        [
          /(?:(amd|x(?:(?:86|64)[-_])?|wow|win)64)[;\)]/i
          // AMD64 (x64)
        ],
        [[x, "amd64"]],
        [
          /(ia32(?=;))/i
          // IA32 (quicktime)
        ],
        [[x, N]],
        [
          /((?:i[346]|x)86)[;\)]/i
          // IA32 (x86)
        ],
        [[x, "ia32"]],
        [
          /\b(aarch64|arm(v?8e?l?|_?64))\b/i
          // ARM64
        ],
        [[x, "arm64"]],
        [
          /\b(arm(?:v[67])?ht?n?[fl]p?)\b/i
          // ARMHF
        ],
        [[x, "armhf"]],
        [
          // PocketPC mistakenly identified as PowerPC
          /windows (ce|mobile); ppc;/i
        ],
        [[x, "arm"]],
        [
          /((?:ppc|powerpc)(?:64)?)(?: mac|;|\))/i
          // PowerPC
        ],
        [[x, /ower/, g, N]],
        [
          /(sun4\w)[;\)]/i
          // SPARC
        ],
        [[x, "sparc"]],
        [
          /((?:avr32|ia64(?=;))|68k(?=\))|\barm(?=v(?:[1-7]|[5-7]1)l?|;|eabi)|(?=atmel )avr|(?:irix|mips|sparc)(?:64)?\b|pa-risc)/i
          // IA64, 68K, ARM/64, AVR/32, IRIX/64, MIPS/64, SPARC/64, PA-RISC
        ],
        [[x, N]]
      ],
      device: [
        [
          //////////////////////////
          // MOBILES & TABLETS
          /////////////////////////
          // Samsung
          /\b(sch-i[89]0\d|shw-m380s|sm-[ptx]\w{2,4}|gt-[pn]\d{2,4}|sgh-t8[56]9|nexus 10)/i
        ],
        [e, [o, X], [i, w]],
        [
          /\b((?:s[cgp]h|gt|sm)-\w+|sc[g-]?[\d]+a?|galaxy nexus)/i,
          /samsung[- ]([-\w]+)/i,
          /sec-(sgh\w+)/i
        ],
        [e, [o, X], [i, d]],
        [
          // Apple
          /(?:\/|\()(ip(?:hone|od)[\w, ]*)(?:\/|;)/i
          // iPod/iPhone
        ],
        [e, [o, R], [i, d]],
        [
          /\((ipad);[-\w\),; ]+apple/i,
          // iPad
          /applecoremedia\/[\w\.]+ \((ipad)/i,
          /\b(ipad)\d\d?,\d\d?[;\]].+ios/i
        ],
        [e, [o, R], [i, w]],
        [
          /(macintosh);/i
        ],
        [e, [o, R]],
        [
          // Sharp
          /\b(sh-?[altvz]?\d\d[a-ekm]?)/i
        ],
        [e, [o, wi], [i, d]],
        [
          // Huawei
          /\b((?:ag[rs][23]?|bah2?|sht?|btv)-a?[lw]\d{2})\b(?!.+d\/s)/i
        ],
        [e, [o, bi], [i, w]],
        [
          /(?:huawei|honor)([-\w ]+)[;\)]/i,
          /\b(nexus 6p|\w{2,4}e?-[atu]?[ln][\dx][012359c][adn]?)\b(?!.+d\/s)/i
        ],
        [e, [o, bi], [i, d]],
        [
          // Xiaomi
          /\b(poco[\w ]+)(?: bui|\))/i,
          // Xiaomi POCO
          /\b; (\w+) build\/hm\1/i,
          // Xiaomi Hongmi 'numeric' models
          /\b(hm[-_ ]?note?[_ ]?(?:\d\w)?) bui/i,
          // Xiaomi Hongmi
          /\b(redmi[\-_ ]?(?:note|k)?[\w_ ]+)(?: bui|\))/i,
          // Xiaomi Redmi
          /\b(mi[-_ ]?(?:a\d|one|one[_ ]plus|note lte|max|cc)?[_ ]?(?:\d?\w?)[_ ]?(?:plus|se|lite)?)(?: bui|\))/i
          // Xiaomi Mi
        ],
        [[e, /_/g, " "], [o, K], [i, d]],
        [
          /\b(mi[-_ ]?(?:pad)(?:[\w_ ]+))(?: bui|\))/i
          // Mi Pad tablets
        ],
        [[e, /_/g, " "], [o, K], [i, w]],
        [
          // OPPO
          /; (\w+) bui.+ oppo/i,
          /\b(cph[12]\d{3}|p(?:af|c[al]|d\w|e[ar])[mt]\d0|x9007|a101op)\b/i
        ],
        [e, [o, "OPPO"], [i, d]],
        [
          // Vivo
          /vivo (\w+)(?: bui|\))/i,
          /\b(v[12]\d{3}\w?[at])(?: bui|;)/i
        ],
        [e, [o, "Vivo"], [i, d]],
        [
          // Realme
          /\b(rmx[12]\d{3})(?: bui|;|\))/i
        ],
        [e, [o, "Realme"], [i, d]],
        [
          // Motorola
          /\b(milestone|droid(?:[2-4x]| (?:bionic|x2|pro|razr))?:?( 4g)?)\b[\w ]+build\//i,
          /\bmot(?:orola)?[- ](\w*)/i,
          /((?:moto[\w\(\) ]+|xt\d{3,4}|nexus 6)(?= bui|\)))/i
        ],
        [e, [o, li], [i, d]],
        [
          /\b(mz60\d|xoom[2 ]{0,2}) build\//i
        ],
        [e, [o, li], [i, w]],
        [
          // LG
          /((?=lg)?[vl]k\-?\d{3}) bui| 3\.[-\w; ]{10}lg?-([06cv9]{3,4})/i
        ],
        [e, [o, $], [i, w]],
        [
          /(lm(?:-?f100[nv]?|-[\w\.]+)(?= bui|\))|nexus [45])/i,
          /\blg[-e;\/ ]+((?!browser|netcast|android tv)\w+)/i,
          /\blg-?([\d\w]+) bui/i
        ],
        [e, [o, $], [i, d]],
        [
          // Lenovo
          /(ideatab[-\w ]+)/i,
          /lenovo ?(s[56]000[-\w]+|tab(?:[\w ]+)|yt[-\d\w]{6}|tb[-\d\w]{6})/i
        ],
        [e, [o, "Lenovo"], [i, w]],
        [
          // Nokia
          /(?:maemo|nokia).*(n900|lumia \d+)/i,
          /nokia[-_ ]?([-\w\.]*)/i
        ],
        [[e, /_/g, " "], [o, "Nokia"], [i, d]],
        [
          // Google
          /(pixel c)\b/i
          // Google Pixel C
        ],
        [e, [o, q], [i, w]],
        [
          /droid.+; (pixel[\daxl ]{0,6})(?: bui|\))/i
          // Google Pixel
        ],
        [e, [o, q], [i, d]],
        [
          // Sony
          /droid.+ (a?\d[0-2]{2}so|[c-g]\d{4}|so[-gl]\w+|xq-a\w[4-7][12])(?= bui|\).+chrome\/(?![1-6]{0,1}\d\.))/i
        ],
        [e, [o, L], [i, d]],
        [
          /sony tablet [ps]/i,
          /\b(?:sony)?sgp\w+(?: bui|\))/i
        ],
        [[e, "Xperia Tablet"], [o, L], [i, w]],
        [
          // OnePlus
          / (kb2005|in20[12]5|be20[12][59])\b/i,
          /(?:one)?(?:plus)? (a\d0\d\d)(?: b|\))/i
        ],
        [e, [o, "OnePlus"], [i, d]],
        [
          // Amazon
          /(alexa)webm/i,
          /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i,
          // Kindle Fire without Silk / Echo Show
          /(kf[a-z]+)( bui|\)).+silk\//i
          // Kindle Fire HD
        ],
        [e, [o, j], [i, w]],
        [
          /((?:sd|kf)[0349hijorstuw]+)( bui|\)).+silk\//i
          // Fire Phone
        ],
        [[e, /(.+)/g, "Fire Phone $1"], [o, j], [i, d]],
        [
          // BlackBerry
          /(playbook);[-\w\),; ]+(rim)/i
          // BlackBerry PlayBook
        ],
        [e, o, [i, w]],
        [
          /\b((?:bb[a-f]|st[hv])100-\d)/i,
          /\(bb10; (\w+)/i
          // BlackBerry 10
        ],
        [e, [o, ci], [i, d]],
        [
          // Asus
          /(?:\b|asus_)(transfo[prime ]{4,10} \w+|eeepc|slider \w+|nexus 7|padfone|p00[cj])/i
        ],
        [e, [o, si], [i, w]],
        [
          / (z[bes]6[027][012][km][ls]|zenfone \d\w?)\b/i
        ],
        [e, [o, si], [i, d]],
        [
          // HTC
          /(nexus 9)/i
          // HTC Nexus 9
        ],
        [e, [o, "HTC"], [i, w]],
        [
          /(htc)[-;_ ]{1,2}([\w ]+(?=\)| bui)|\w+)/i,
          // HTC
          // ZTE
          /(zte)[- ]([\w ]+?)(?: bui|\/|\))/i,
          /(alcatel|geeksphone|nexian|panasonic(?!(?:;|\.))|sony(?!-bra))[-_ ]?([-\w]*)/i
          // Alcatel/GeeksPhone/Nexian/Panasonic/Sony
        ],
        [o, [e, /_/g, " "], [i, d]],
        [
          // Acer
          /droid.+; ([ab][1-7]-?[0178a]\d\d?)/i
        ],
        [e, [o, "Acer"], [i, w]],
        [
          // Meizu
          /droid.+; (m[1-5] note) bui/i,
          /\bmz-([-\w]{2,})/i
        ],
        [e, [o, "Meizu"], [i, d]],
        [
          // MIXED
          /(blackberry|benq|palm(?=\-)|sonyericsson|acer|asus|dell|meizu|motorola|polytron)[-_ ]?([-\w]*)/i,
          // BlackBerry/BenQ/Palm/Sony-Ericsson/Acer/Asus/Dell/Meizu/Motorola/Polytron
          /(hp) ([\w ]+\w)/i,
          // HP iPAQ
          /(asus)-?(\w+)/i,
          // Asus
          /(microsoft); (lumia[\w ]+)/i,
          // Microsoft Lumia
          /(lenovo)[-_ ]?([-\w]+)/i,
          // Lenovo
          /(jolla)/i,
          // Jolla
          /(oppo) ?([\w ]+) bui/i
          // OPPO
        ],
        [o, e, [i, d]],
        [
          /(kobo)\s(ereader|touch)/i,
          // Kobo
          /(archos) (gamepad2?)/i,
          // Archos
          /(hp).+(touchpad(?!.+tablet)|tablet)/i,
          // HP TouchPad
          /(kindle)\/([\w\.]+)/i,
          // Kindle
          /(nook)[\w ]+build\/(\w+)/i,
          // Nook
          /(dell) (strea[kpr\d ]*[\dko])/i,
          // Dell Streak
          /(le[- ]+pan)[- ]+(\w{1,9}) bui/i,
          // Le Pan Tablets
          /(trinity)[- ]*(t\d{3}) bui/i,
          // Trinity Tablets
          /(gigaset)[- ]+(q\w{1,9}) bui/i,
          // Gigaset Tablets
          /(vodafone) ([\w ]+)(?:\)| bui)/i
          // Vodafone
        ],
        [o, e, [i, w]],
        [
          /(surface duo)/i
          // Surface Duo
        ],
        [e, [o, Z], [i, w]],
        [
          /droid [\d\.]+; (fp\du?)(?: b|\))/i
          // Fairphone
        ],
        [e, [o, "Fairphone"], [i, d]],
        [
          /(u304aa)/i
          // AT&T
        ],
        [e, [o, "AT&T"], [i, d]],
        [
          /\bsie-(\w*)/i
          // Siemens
        ],
        [e, [o, "Siemens"], [i, d]],
        [
          /\b(rct\w+) b/i
          // RCA Tablets
        ],
        [e, [o, "RCA"], [i, w]],
        [
          /\b(venue[\d ]{2,7}) b/i
          // Dell Venue Tablets
        ],
        [e, [o, "Dell"], [i, w]],
        [
          /\b(q(?:mv|ta)\w+) b/i
          // Verizon Tablet
        ],
        [e, [o, "Verizon"], [i, w]],
        [
          /\b(?:barnes[& ]+noble |bn[rt])([\w\+ ]*) b/i
          // Barnes & Noble Tablet
        ],
        [e, [o, "Barnes & Noble"], [i, w]],
        [
          /\b(tm\d{3}\w+) b/i
        ],
        [e, [o, "NuVision"], [i, w]],
        [
          /\b(k88) b/i
          // ZTE K Series Tablet
        ],
        [e, [o, "ZTE"], [i, w]],
        [
          /\b(nx\d{3}j) b/i
          // ZTE Nubia
        ],
        [e, [o, "ZTE"], [i, d]],
        [
          /\b(gen\d{3}) b.+49h/i
          // Swiss GEN Mobile
        ],
        [e, [o, "Swiss"], [i, d]],
        [
          /\b(zur\d{3}) b/i
          // Swiss ZUR Tablet
        ],
        [e, [o, "Swiss"], [i, w]],
        [
          /\b((zeki)?tb.*\b) b/i
          // Zeki Tablets
        ],
        [e, [o, "Zeki"], [i, w]],
        [
          /\b([yr]\d{2}) b/i,
          /\b(dragon[- ]+touch |dt)(\w{5}) b/i
          // Dragon Touch Tablet
        ],
        [[o, "Dragon Touch"], e, [i, w]],
        [
          /\b(ns-?\w{0,9}) b/i
          // Insignia Tablets
        ],
        [e, [o, "Insignia"], [i, w]],
        [
          /\b((nxa|next)-?\w{0,9}) b/i
          // NextBook Tablets
        ],
        [e, [o, "NextBook"], [i, w]],
        [
          /\b(xtreme\_)?(v(1[045]|2[015]|[3469]0|7[05])) b/i
          // Voice Xtreme Phones
        ],
        [[o, "Voice"], e, [i, d]],
        [
          /\b(lvtel\-)?(v1[12]) b/i
          // LvTel Phones
        ],
        [[o, "LvTel"], e, [i, d]],
        [
          /\b(ph-1) /i
          // Essential PH-1
        ],
        [e, [o, "Essential"], [i, d]],
        [
          /\b(v(100md|700na|7011|917g).*\b) b/i
          // Envizen Tablets
        ],
        [e, [o, "Envizen"], [i, w]],
        [
          /\b(trio[-\w\. ]+) b/i
          // MachSpeed Tablets
        ],
        [e, [o, "MachSpeed"], [i, w]],
        [
          /\btu_(1491) b/i
          // Rotor Tablets
        ],
        [e, [o, "Rotor"], [i, w]],
        [
          /(shield[\w ]+) b/i
          // Nvidia Shield Tablets
        ],
        [e, [o, "Nvidia"], [i, w]],
        [
          /(sprint) (\w+)/i
          // Sprint Phones
        ],
        [o, e, [i, d]],
        [
          /(kin\.[onetw]{3})/i
          // Microsoft Kin
        ],
        [[e, /\./g, " "], [o, Z], [i, d]],
        [
          /droid.+; (cc6666?|et5[16]|mc[239][23]x?|vc8[03]x?)\)/i
          // Zebra
        ],
        [e, [o, Q], [i, w]],
        [
          /droid.+; (ec30|ps20|tc[2-8]\d[kx])\)/i
        ],
        [e, [o, Q], [i, d]],
        [
          ///////////////////
          // SMARTTVS
          ///////////////////
          /smart-tv.+(samsung)/i
          // Samsung
        ],
        [o, [i, h]],
        [
          /hbbtv.+maple;(\d+)/i
        ],
        [[e, /^/, "SmartTV"], [o, X], [i, h]],
        [
          /(nux; netcast.+smarttv|lg (netcast\.tv-201\d|android tv))/i
          // LG SmartTV
        ],
        [[o, $], [i, h]],
        [
          /(apple) ?tv/i
          // Apple TV
        ],
        [o, [e, R + " TV"], [i, h]],
        [
          /crkey/i
          // Google Chromecast
        ],
        [[e, I + "cast"], [o, q], [i, h]],
        [
          /droid.+aft(\w)( bui|\))/i
          // Fire TV
        ],
        [e, [o, j], [i, h]],
        [
          /\(dtv[\);].+(aquos)/i,
          /(aquos-tv[\w ]+)\)/i
          // Sharp
        ],
        [e, [o, wi], [i, h]],
        [
          /(bravia[\w ]+)( bui|\))/i
          // Sony
        ],
        [e, [o, L], [i, h]],
        [
          /(mitv-\w{5}) bui/i
          // Xiaomi
        ],
        [e, [o, K], [i, h]],
        [
          /Hbbtv.*(technisat) (.*);/i
          // TechniSAT
        ],
        [o, e, [i, h]],
        [
          /\b(roku)[\dx]*[\)\/]((?:dvp-)?[\d\.]*)/i,
          // Roku
          /hbbtv\/\d+\.\d+\.\d+ +\([\w\+ ]*; *([\w\d][^;]*);([^;]*)/i
          // HbbTV devices
        ],
        [[o, ii], [e, ii], [i, h]],
        [
          /\b(android tv|smart[- ]?tv|opera tv|tv; rv:)\b/i
          // SmartTV from Unidentified Vendors
        ],
        [[i, h]],
        [
          ///////////////////
          // CONSOLES
          ///////////////////
          /(ouya)/i,
          // Ouya
          /(nintendo) ([wids3utch]+)/i
          // Nintendo
        ],
        [o, e, [i, A]],
        [
          /droid.+; (shield) bui/i
          // Nvidia
        ],
        [e, [o, "Nvidia"], [i, A]],
        [
          /(playstation [345portablevi]+)/i
          // Playstation
        ],
        [e, [o, L], [i, A]],
        [
          /\b(xbox(?: one)?(?!; xbox))[\); ]/i
          // Microsoft Xbox
        ],
        [e, [o, Z], [i, A]],
        [
          ///////////////////
          // WEARABLES
          ///////////////////
          /((pebble))app/i
          // Pebble
        ],
        [o, e, [i, E]],
        [
          /(watch)(?: ?os[,\/]|\d,\d\/)[\d\.]+/i
          // Apple Watch
        ],
        [e, [o, R], [i, E]],
        [
          /droid.+; (glass) \d/i
          // Google Glass
        ],
        [e, [o, q], [i, E]],
        [
          /droid.+; (wt63?0{2,3})\)/i
        ],
        [e, [o, Q], [i, E]],
        [
          /(quest( 2| pro)?)/i
          // Oculus Quest
        ],
        [e, [o, di], [i, E]],
        [
          ///////////////////
          // EMBEDDED
          ///////////////////
          /(tesla)(?: qtcarbrowser|\/[-\w\.]+)/i
          // Tesla
        ],
        [o, [i, Y]],
        [
          /(aeobc)\b/i
          // Echo Dot
        ],
        [e, [o, j], [i, Y]],
        [
          ////////////////////
          // MIXED (GENERIC)
          ///////////////////
          /droid .+?; ([^;]+?)(?: bui|\) applew).+? mobile safari/i
          // Android Phones from Unidentified Vendors
        ],
        [e, [i, d]],
        [
          /droid .+?; ([^;]+?)(?: bui|\) applew).+?(?! mobile) safari/i
          // Android Tablets from Unidentified Vendors
        ],
        [e, [i, w]],
        [
          /\b((tablet|tab)[;\/]|focus\/\d(?!.+mobile))/i
          // Unidentifiable Tablet
        ],
        [[i, w]],
        [
          /(phone|mobile(?:[;\/]| [ \w\/\.]*safari)|pda(?=.+windows ce))/i
          // Unidentifiable Mobile
        ],
        [[i, d]],
        [
          /(android[-\w\. ]{0,9});.+buil/i
          // Generic Android Device
        ],
        [e, [o, "Generic"]]
      ],
      engine: [
        [
          /windows.+ edge\/([\w\.]+)/i
          // EdgeHTML
        ],
        [a, [r, yi + "HTML"]],
        [
          /webkit\/537\.36.+chrome\/(?!27)([\w\.]+)/i
          // Blink
        ],
        [a, [r, "Blink"]],
        [
          /(presto)\/([\w\.]+)/i,
          // Presto
          /(webkit|trident|netfront|netsurf|amaya|lynx|w3m|goanna)\/([\w\.]+)/i,
          // WebKit/Trident/NetFront/NetSurf/Amaya/Lynx/w3m/Goanna
          /ekioh(flow)\/([\w\.]+)/i,
          // Flow
          /(khtml|tasman|links)[\/ ]\(?([\w\.]+)/i,
          // KHTML/Tasman/Links
          /(icab)[\/ ]([23]\.[\d\.]+)/i,
          // iCab
          /\b(libweb)/i
        ],
        [r, a],
        [
          /rv\:([\w\.]{1,9})\b.+(gecko)/i
          // Gecko
        ],
        [a, r]
      ],
      os: [
        [
          // Windows
          /microsoft (windows) (vista|xp)/i
          // Windows (iTunes)
        ],
        [r, a],
        [
          /(windows) nt 6\.2; (arm)/i,
          // Windows RT
          /(windows (?:phone(?: os)?|mobile))[\/ ]?([\d\.\w ]*)/i,
          // Windows Phone
          /(windows)[\/ ]?([ntce\d\. ]+\w)(?!.+xbox)/i
        ],
        [r, [a, ei, fi]],
        [
          /(win(?=3|9|n)|win 9x )([nt\d\.]+)/i
        ],
        [[r, "Windows"], [a, ei, fi]],
        [
          // iOS/macOS
          /ip[honead]{2,4}\b(?:.*os ([\w]+) like mac|; opera)/i,
          // iOS
          /ios;fbsv\/([\d\.]+)/i,
          /cfnetwork\/.+darwin/i
        ],
        [[a, /_/g, "."], [r, "iOS"]],
        [
          /(mac os x) ?([\w\. ]*)/i,
          /(macintosh|mac_powerpc\b)(?!.+haiku)/i
          // Mac OS
        ],
        [[r, pi], [a, /_/g, "."]],
        [
          // Mobile OSes
          /droid ([\w\.]+)\b.+(android[- ]x86|harmonyos)/i
          // Android-x86/HarmonyOS
        ],
        [a, r],
        [
          // Android/WebOS/QNX/Bada/RIM/Maemo/MeeGo/Sailfish OS
          /(android|webos|qnx|bada|rim tablet os|maemo|meego|sailfish)[-\/ ]?([\w\.]*)/i,
          /(blackberry)\w*\/([\w\.]*)/i,
          // Blackberry
          /(tizen|kaios)[\/ ]([\w\.]+)/i,
          // Tizen/KaiOS
          /\((series40);/i
          // Series 40
        ],
        [r, a],
        [
          /\(bb(10);/i
          // BlackBerry 10
        ],
        [a, [r, ci]],
        [
          /(?:symbian ?os|symbos|s60(?=;)|series60)[-\/ ]?([\w\.]*)/i
          // Symbian
        ],
        [a, [r, "Symbian"]],
        [
          /mozilla\/[\d\.]+ \((?:mobile|tablet|tv|mobile; [\w ]+); rv:.+ gecko\/([\w\.]+)/i
          // Firefox OS
        ],
        [a, [r, z + " OS"]],
        [
          /web0s;.+rt(tv)/i,
          /\b(?:hp)?wos(?:browser)?\/([\w\.]+)/i
          // WebOS
        ],
        [a, [r, "webOS"]],
        [
          /watch(?: ?os[,\/]|\d,\d\/)([\d\.]+)/i
          // watchOS
        ],
        [a, [r, "watchOS"]],
        [
          // Google Chromecast
          /crkey\/([\d\.]+)/i
          // Google Chromecast
        ],
        [a, [r, I + "cast"]],
        [
          /(cros) [\w]+(?:\)| ([\w\.]+)\b)/i
          // Chromium OS
        ],
        [[r, ui], a],
        [
          // Smart TVs
          /panasonic;(viera)/i,
          // Panasonic Viera
          /(netrange)mmh/i,
          // Netrange
          /(nettv)\/(\d+\.[\w\.]+)/i,
          // NetTV
          // Console
          /(nintendo|playstation) ([wids345portablevuch]+)/i,
          // Nintendo/Playstation
          /(xbox); +xbox ([^\);]+)/i,
          // Microsoft Xbox (360, One, X, S, Series X, Series S)
          // Other
          /\b(joli|palm)\b ?(?:os)?\/?([\w\.]*)/i,
          // Joli/Palm
          /(mint)[\/\(\) ]?(\w*)/i,
          // Mint
          /(mageia|vectorlinux)[; ]/i,
          // Mageia/VectorLinux
          /([kxln]?ubuntu|debian|suse|opensuse|gentoo|arch(?= linux)|slackware|fedora|mandriva|centos|pclinuxos|red ?hat|zenwalk|linpus|raspbian|plan 9|minix|risc os|contiki|deepin|manjaro|elementary os|sabayon|linspire)(?: gnu\/linux)?(?: enterprise)?(?:[- ]linux)?(?:-gnu)?[-\/ ]?(?!chrom|package)([-\w\.]*)/i,
          // Ubuntu/Debian/SUSE/Gentoo/Arch/Slackware/Fedora/Mandriva/CentOS/PCLinuxOS/RedHat/Zenwalk/Linpus/Raspbian/Plan9/Minix/RISCOS/Contiki/Deepin/Manjaro/elementary/Sabayon/Linspire
          /(hurd|linux) ?([\w\.]*)/i,
          // Hurd/Linux
          /(gnu) ?([\w\.]*)/i,
          // GNU
          /\b([-frentopcghs]{0,5}bsd|dragonfly)[\/ ]?(?!amd|[ix346]{1,2}86)([\w\.]*)/i,
          // FreeBSD/NetBSD/OpenBSD/PC-BSD/GhostBSD/DragonFly
          /(haiku) (\w+)/i
          // Haiku
        ],
        [r, a],
        [
          /(sunos) ?([\w\.\d]*)/i
          // Solaris
        ],
        [[r, "Solaris"], a],
        [
          /((?:open)?solaris)[-\/ ]?([\w\.]*)/i,
          // Solaris
          /(aix) ((\d)(?=\.|\)| )[\w\.])*/i,
          // AIX
          /\b(beos|os\/2|amigaos|morphos|openvms|fuchsia|hp-ux|serenityos)/i,
          // BeOS/OS2/AmigaOS/MorphOS/OpenVMS/Fuchsia/HP-UX/SerenityOS
          /(unix) ?([\w\.]*)/i
          // UNIX
        ],
        [r, a]
      ]
    }, k = function(u, m) {
      if (typeof u === W && (m = u, u = c), !(this instanceof k))
        return new k(u, m).getResult();
      var l = typeof s !== P && s.navigator ? s.navigator : c, f = u || (l && l.userAgent ? l.userAgent : g), O = l && l.userAgentData ? l.userAgentData : c, y = m ? Si(gi, m) : gi, p = l && l.userAgent == f;
      return this.getBrowser = function() {
        var b = {};
        return b[r] = c, b[a] = c, C.call(b, f, y.browser), b[ni] = Oi(b[a]), p && l && l.brave && typeof l.brave.isBrave == F && (b[r] = "Brave"), b;
      }, this.getCPU = function() {
        var b = {};
        return b[x] = c, C.call(b, f, y.cpu), b;
      }, this.getDevice = function() {
        var b = {};
        return b[o] = c, b[e] = c, b[i] = c, C.call(b, f, y.device), p && !b[i] && O && O.mobile && (b[i] = d), p && b[e] == "Macintosh" && l && typeof l.standalone !== P && l.maxTouchPoints && l.maxTouchPoints > 2 && (b[e] = "iPad", b[i] = w), b;
      }, this.getEngine = function() {
        var b = {};
        return b[r] = c, b[a] = c, C.call(b, f, y.engine), b;
      }, this.getOS = function() {
        var b = {};
        return b[r] = c, b[a] = c, C.call(b, f, y.os), p && !b[r] && O && O.platform != "Unknown" && (b[r] = O.platform.replace(/chrome os/i, ui).replace(/macos/i, pi)), b;
      }, this.getResult = function() {
        return {
          ua: this.getUA(),
          browser: this.getBrowser(),
          engine: this.getEngine(),
          os: this.getOS(),
          device: this.getDevice(),
          cpu: this.getCPU()
        };
      }, this.getUA = function() {
        return f;
      }, this.setUA = function(b) {
        return f = typeof b === M && b.length > J ? ii(b, J) : b, this;
      }, this.setUA(f), this;
    };
    k.VERSION = v, k.BROWSER = D([r, a, ni]), k.CPU = D([x]), k.DEVICE = D([e, o, i, A, d, h, w, E, Y]), k.ENGINE = k.OS = D([r, a]), t.exports && (n = t.exports = k), n.UAParser = k;
    var _ = typeof s !== P && (s.jQuery || s.Zepto);
    if (_ && !_.ua) {
      var V = new k();
      _.ua = V.getResult(), _.ua.get = function() {
        return V.getUA();
      }, _.ua.set = function(u) {
        V.setUA(u);
        var m = V.getResult();
        for (var l in m)
          _.ua[l] = m[l];
      };
    }
  })(typeof window == "object" ? window : Ri);
})(Ni, U);
const Ci = new U.UAParser(), Yi = Ci.getDevice().type === U.UAParser.DEVICE.MOBILE;
export {
  Ci as UAParser,
  zi as copyToClipboard,
  ji as deleteParam,
  _i as getCookie,
  Gi as getStorageItem,
  Pi as getUrlParameter,
  Wi as hexToRgba,
  Yi as isMobile,
  Di as loadGoogleAnalytics,
  Li as loadGoogleRecaptcha,
  ki as loadScriptAsync,
  Ui as openInNewTab,
  qi as printPackageInfo,
  Bi as redirect,
  Vi as scrollElementToView,
  Ii as setCookie,
  Mi as setParam,
  Hi as setStorageItem,
  Fi as toClasses
};
