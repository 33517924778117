import n from "axios";
import { mergeDeep as p } from "@starkware-webapps/utils";
const l = {
  headers: {
    "Content-Type": "application/json"
  }
}, u = (t = {}) => n.create(p({}, l, t)), E = (t) => {
  let s = "Request Error", e = "", r = null;
  if (t)
    if (t.response) {
      const { statusText: a, data: o, status: i } = t.response;
      s = a, e = o.message || o, r = i;
    } else
      ({ message: e } = t);
  return { title: s, message: e, code: r };
}, f = (t) => n.isAxiosError(t);
export {
  u as createHttpClient,
  f as isHttpError,
  E as parseHttpClientError
};
