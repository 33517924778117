var p = Object.defineProperty;
var I = (e, a, t) => a in e ? p(e, a, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[a] = t;
var o = (e, a, t) => (I(e, typeof a != "symbol" ? a + "" : a, t), t);
import { constants as i, RpcProvider as T, LibraryError as A, Account as E } from "starknet";
var l = /* @__PURE__ */ ((e) => (e.ALCHEMY = "alchemy", e.BLAST = "blast", e.CHAINSTACK = "chainstack", e.INFURA = "infura", e))(l || {});
const S = {
  [l.ALCHEMY]: {
    [i.StarknetChainId.SN_MAIN]: "starknet-mainnet",
    [i.StarknetChainId.SN_GOERLI]: "starknet-goerli",
    [i.StarknetChainId.SN_SEPOLIA]: "starknet-sepolia"
  },
  [l.BLAST]: {
    [i.StarknetChainId.SN_MAIN]: "starknet-mainnet",
    [i.StarknetChainId.SN_GOERLI]: "starknet-testnet",
    [i.StarknetChainId.SN_SEPOLIA]: "starknet-sepolia"
  },
  [l.CHAINSTACK]: {
    [i.StarknetChainId.SN_MAIN]: "",
    [i.StarknetChainId.SN_GOERLI]: "",
    [i.StarknetChainId.SN_SEPOLIA]: ""
  },
  [l.INFURA]: {
    [i.StarknetChainId.SN_MAIN]: "mainnet",
    [i.StarknetChainId.SN_GOERLI]: "goerli",
    [i.StarknetChainId.SN_SEPOLIA]: "sepolia"
  }
}, k = {
  [l.ALCHEMY]: (e, a) => `https://${a}.g.alchemy.com/v2/${e.apiKey}`,
  [l.BLAST]: (e, a) => `https://${a}.blastapi.io/${e.apiKey}/rpc/v0.5`,
  [l.CHAINSTACK]: (e) => `https://nd-${e.nodeId}.p2pify.com/${e.apiKey}/rpc/v0.5`,
  [l.INFURA]: (e, a) => `https://${a}.infura.io/v3/${e.apiKey}`
}, C = (e, a) => {
  const t = N(e, a.name);
  return k[a.name](a, t);
}, N = (e, a) => {
  const t = S[a][e];
  if (t === null)
    throw new Error(`Chain ${e} is not supported for ${a}.`);
  return t;
}, u = class {
  constructor(a) {
    this.bundles = a;
  }
  static create(a) {
    const { chainId: t, providers: r } = a;
    if (r.length === 0)
      throw new Error("No providers passed.");
    const n = r.map((s) => {
      const c = "baseUrl" in s ? s.baseUrl : C(t, s);
      return {
        provider: new T({ nodeUrl: c, chainId: t }),
        isActive: !0,
        retryDelayMultiplier: u.INITIAL_RETRY_MULTIPLIER
      };
    });
    return new u(n);
  }
  async call(a) {
    for (const t of this.bundles)
      if (t.isActive)
        try {
          const r = await a(t.provider);
          return this.resetBundle(t), r;
        } catch (r) {
          this.handleProviderError(t, r);
        }
    throw new Error("No available providers to handle the request.");
  }
  resetBundle(a) {
    a.retryDelayMultiplier = u.INITIAL_RETRY_MULTIPLIER, a.isActive = !0;
  }
  handleProviderError(a, t) {
    if (t instanceof A)
      throw this.resetBundle(a), t;
    a.isActive && this.deactivateProvider(a);
  }
  deactivateProvider(a) {
    a.isActive = !1, setTimeout(
      () => this.resetBundle(a),
      a.retryDelayMultiplier * u.BASE_RETRY_DELAY
    ), a.retryDelayMultiplier *= u.BACKOFF_EXPONENT;
  }
};
let g = u;
o(g, "INITIAL_RETRY_MULTIPLIER", 1), o(g, "BASE_RETRY_DELAY", 2e3), o(g, "BACKOFF_EXPONENT", 2);
class y {
  constructor(a) {
    o(this, "getBlockHashAndNumber", this.getBlockLatestAccepted);
    o(this, "getStateUpdate", this.getBlockStateUpdate);
    o(this, "traceBlockTransactions", this.getBlockTransactionsTraces);
    o(this, "traceTransaction", this.getTransactionTrace);
    o(this, "getSimulateTransaction", this.simulateTransaction);
    this.manager = a;
  }
  static create(a) {
    const t = g.create(a);
    return new y(t);
  }
  async getTransactionStatus(a) {
    return await this.manager.call(async (t) => await t.getTransactionStatus(a));
  }
  async estimateMessageFee(a, t) {
    return await this.manager.call(async (r) => await r.estimateMessageFee(a, t));
  }
  async simulateTransaction(a, { blockIdentifier: t, skipValidate: r, skipExecute: n, skipFeeCharge: s } = {}) {
    return await this.manager.call(async (c) => await c.simulateTransaction(a, {
      blockIdentifier: t,
      skipValidate: r,
      skipExecute: n,
      skipFeeCharge: s
    }));
  }
  async getTransactionByHash(a) {
    return await this.manager.call(async (t) => await t.getTransactionByHash(a));
  }
  async getTransactionReceipt(a) {
    return await this.manager.call(async (t) => await t.getTransactionReceipt(a));
  }
  async callContract(a, t) {
    return await this.manager.call(async (r) => await r.callContract(a, t));
  }
  async waitForTransaction(a, t) {
    return await this.manager.call(async (r) => await r.waitForTransaction(a, t));
  }
  async getClassAt(a, t) {
    return await this.manager.call(async (r) => await r.getClassAt(a, t));
  }
  async getClassByHash(a) {
    return await this.manager.call(async (t) => await t.getClassByHash(a));
  }
  async getChainId() {
    return await this.manager.call(async (a) => await a.getChainId());
  }
  async getSpecVersion() {
    return await this.manager.call(async (a) => await a.getSpecVersion());
  }
  async getNonceForAddress(a, t) {
    return await this.manager.call(async (r) => await r.getNonceForAddress(a, t));
  }
  async getBlock(a) {
    return await this.manager.call(async (t) => await t.getBlock(a));
  }
  async getBlockLatestAccepted() {
    return await this.manager.call(async (a) => await a.getBlockLatestAccepted());
  }
  async getBlockNumber() {
    return await this.manager.call(async (a) => await a.getBlockNumber());
  }
  async getBlockWithTxHashes(a) {
    return await this.manager.call(async (t) => await t.getBlockWithTxHashes(a));
  }
  async getBlockStateUpdate(a) {
    return await this.manager.call(async (t) => await t.getBlockStateUpdate(a));
  }
  async getBlockTransactionsTraces(a) {
    return await this.manager.call(async (t) => await t.getBlockTransactionsTraces(a));
  }
  async getBlockTransactionCount(a) {
    return await this.manager.call(async (t) => await t.getBlockTransactionCount(a));
  }
  async getPendingTransactions() {
    return await this.manager.call(async (a) => await a.getPendingTransactions());
  }
  async getTransaction(a) {
    return await this.manager.call(async (t) => await t.getTransaction(a));
  }
  async getTransactionByBlockIdAndIndex(a, t) {
    return await this.manager.call(async (r) => await r.getTransactionByBlockIdAndIndex(a, t));
  }
  async getTransactionTrace(a) {
    return await this.manager.call(async (t) => await t.getTransactionTrace(a));
  }
  async getStorageAt(a, t, r) {
    return await this.manager.call(async (n) => await n.getStorageAt(a, t, r));
  }
  async getClassHashAt(a, t) {
    return await this.manager.call(async (r) => await r.getClassHashAt(a, t));
  }
  async getClass(a, t) {
    return await this.manager.call(async (r) => await r.getClass(a, t));
  }
  async getContractVersion(a, t, { blockIdentifier: r, compiler: n = !0 } = {}) {
    return await this.manager.call(async (s) => {
      if (a !== void 0)
        return await s.getContractVersion(a, void 0, {
          blockIdentifier: r,
          compiler: n
        });
      if (t !== void 0)
        return await s.getContractVersion(void 0, t, {
          blockIdentifier: r,
          compiler: n
        });
    });
  }
  async getEstimateFee(a, t, r) {
    return await this.manager.call(async (n) => await n.getEstimateFee(a, t, r));
  }
  async getInvokeEstimateFee(a, t, r) {
    return await this.manager.call(async (n) => await n.getInvokeEstimateFee(a, t, r));
  }
  async getDeclareEstimateFee(a, t, r) {
    return await this.manager.call(async (n) => await n.getDeclareEstimateFee(a, t, r));
  }
  async getDeployAccountEstimateFee(a, t, r) {
    return await this.manager.call(async (n) => await n.getDeployAccountEstimateFee(a, t, r));
  }
  async getEstimateFeeBulk(a, { blockIdentifier: t, skipValidate: r = !1 }) {
    return await this.manager.call(async (n) => await n.getEstimateFeeBulk(a, { blockIdentifier: t, skipValidate: r }));
  }
  async invokeFunction(a, t) {
    return await this.manager.call(async (r) => await r.invokeFunction(a, t));
  }
  async declareContract({ contract: a, signature: t, senderAddress: r, compiledClassHash: n }, s) {
    return await this.manager.call(async (c) => await c.declareContract(
      { contract: a, signature: t, senderAddress: r, compiledClassHash: n },
      s
    ));
  }
  async deployAccountContract({ classHash: a, constructorCalldata: t, addressSalt: r, signature: n }, s) {
    return await this.manager.call(async (c) => await c.deployAccountContract(
      { classHash: a, constructorCalldata: t, addressSalt: r, signature: n },
      s
    ));
  }
  async getSyncingStats() {
    return await this.manager.call(async (a) => await a.getSyncingStats());
  }
  async getEvents(a) {
    return await this.manager.call(async (t) => await t.getEvents(a));
  }
  async getStarkName(a, t) {
    return await this.manager.call(async (r) => await r.getStarkName(a, t));
  }
  async getAddressFromStarkName(a, t) {
    return await this.manager.call(async (r) => await r.getAddressFromStarkName(a, t));
  }
  buildTransaction(a, t) {
    return this.manager.call(async (r) => r.buildTransaction(a, t));
  }
  async getCode(a, t) {
    return await this.manager.call(async (r) => await r.getCode(a, t));
  }
}
const m = {
  defined: (e, a) => {
    if (e === void 0)
      throw a instanceof Error ? a : new Error(`'${a}' is undefined`);
  },
  array: {
    notEmpty: (e, a) => {
      if (!e || !e.length)
        throw a instanceof Error ? a : new Error(`Array '${a}' is ${e && "empty"}`);
    }
  },
  map: {
    notEmpty: (e, a) => {
      if (!e || !e.size)
        throw a instanceof Error ? a : new Error(`Map '${a}' is ${e && "empty"}`);
    }
  },
  promise: {
    resolves: async (e, a = "promise threw an error", t = !0) => {
      const r = () => {
        const s = a instanceof Error ? a : new Error(a);
        return Error.captureStackTrace(s, m.promise.resolves), s;
      }, n = t ? r() : void 0;
      try {
        return await e;
      } catch (s) {
        const c = n || r();
        throw c.cause = s, c;
      }
    }
  },
  ok: (e, a) => {
    if (!e)
      throw a instanceof Error ? a : new Error(a);
  }
};
var v = [];
for (var h = 0; h < 256; ++h)
  v.push((h + 256).toString(16).substr(1));
const B = {
  main: i.StarknetChainId.SN_MAIN,
  goerli: i.StarknetChainId.SN_GOERLI
}, L = (e) => {
  if ("chainId" in e)
    return e.chainId;
  m.defined(e.network, "network");
  const a = B[e.network];
  if (!a)
    throw new Error("Unsupported Network.");
  return a;
}, d = class {
  constructor(a, t) {
    this.chainId = a, this.provider = t;
  }
  static create(a) {
    const { providers: t } = a, r = L(a), n = y.create({ chainId: r, providers: t });
    return new d(r, n);
  }
  getProvider() {
    return this.provider;
  }
  createAccount({ address: a, privateKey: t, version: r }) {
    return new E(this.getProvider(), a, t, r);
  }
};
let w = d;
o(w, "Provider", l);
export {
  B as NetworkToChainIdMap,
  y as Provider,
  g as ProviderManager,
  l as ProviderName,
  S as ProviderToNetworkMap,
  w as StarknetConnect,
  C as buildProviderUrl,
  L as getChainId,
  N as toProviderNetwork
};
