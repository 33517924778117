const a = "connected_l1_wallet_id", _ = "connected_l2_wallet_id", o = "Etherscan", c = "Voyager", n = "StarkScan", e = "https://starknet.io/discord", E = "https://testnet.starkscan.co/tx/{{transactionHash}}", r = "https://testnet.starkscan.co/contract/{{account}}", A = "https://starkware.co/", R = "https://starknet.io", t = "https://docs.starknet.io/documentation/develop", s = `${t}/L1-L2_Communication/token-bridge`, T = "starkgate@starknet.io", S = "https://github.com/starkware-libs/starknet-addresses", L = "https://starknet-ecosystem.com", k = "https://github.com/starkware-libs/starkgate-frontend", C = `${s}/#starkgate_alpha_limitations`, N = "https://github.com/starkware-libs/starkgate-contracts/tree/main/src/starkware/starknet/apps/starkgate", O = "https://forms.reform.app/starkware/StarkGate_Feedback/yhyalh", i = "https://forms.reform.app/TeRuSp/StarkGate-Feedback-Mainnet/bcoscx", p = "compliance@starkware.co", K = "https://starkware-web.s3.us-east-2.amazonaws.com", h = 5, D = 1;
export {
  a as CONNECTED_L1_WALLET_ID_COOKIE_NAME,
  _ as CONNECTED_L2_WALLET_ID_COOKIE_NAME,
  e as DISCORD_LINK_URL,
  o as ETHERSCAN,
  O as FEEDBACK_FORM_URL_GOERLI,
  i as FEEDBACK_FORM_URL_MAINNET,
  h as FETCH_TOKEN_BALANCE_MAX_RETRY,
  D as HIDE_ELEMENT_COOKIE_DURATION_DAYS,
  K as S3_ASSETS_BUCKET_URL,
  C as STARKGATE_ALPHA_LIMITATIONS_URL,
  N as STARKGATE_CONTRACTS_REPO_URL,
  s as STARKGATE_DOCS_URL,
  k as STARKGATE_FRONTEND_REPO_URL,
  T as STARKGATE_MAIL_ADDRESS,
  S as STARKNET_ADDRESSES_REPO_URL,
  t as STARKNET_DOCS_URL,
  L as STARKNET_ECOSYSTEM_URL,
  R as STARKNET_SITE_URL,
  n as STARKSCAN,
  r as STARKSCAN_ACCOUNT_URL,
  E as STARKSCAN_TX_URL,
  p as STARKWARE_COMPLIANCE_MAIL_ADDRESS,
  A as STARKWARE_SITE_URL,
  c as VOYAGER
};
