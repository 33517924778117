var e = /* @__PURE__ */ ((E) => (E[E.MAIN = 1] = "MAIN", E[E.GOERLI = 5] = "GOERLI", E[E.SEPOLIA = 11155111] = "SEPOLIA", E))(e || {}), n = /* @__PURE__ */ ((E) => (E.MAIN = "0x534e5f4d41494e", E.GOERLI = "0x534e5f474f45524c49", E.SEPOLIA = "0x534e5f5345504f4c4941", E))(n || {});
const i = {
  L1: e,
  L2: n
}, d = (E) => E === "0x534e5f474f45524c49" || E === "0x534e5f5345504f4c4941", M = (E) => E === "0x534e5f4d41494e", T = (E) => E === 5 || E === 11155111, G = (E) => E === 1, U = {
  L1: {
    [e.MAIN]: {
      NAME: "Ethereum Mainnet",
      EXPLORER_URL: "https://etherscan.io"
    },
    [e.GOERLI]: {
      NAME: "Ethereum Goerli",
      EXPLORER_URL: "https://goerli.etherscan.io"
    },
    [e.SEPOLIA]: {
      NAME: "Ethereum Sepolia",
      EXPLORER_URL: "https://sepolia.etherscan.io"
    }
  },
  L2: {
    [n.MAIN]: {
      CHAIN: "Mainnet",
      NAME: "Starknet Mainnet",
      EXPLORER_URL: "https://starkscan.co"
    },
    [n.GOERLI]: {
      CHAIN: "Goerli",
      NAME: "Starknet Goerli",
      EXPLORER_URL: "https://testnet.starkscan.co"
    },
    [n.SEPOLIA]: {
      CHAIN: "Sepolia",
      NAME: "Starknet Sepolia",
      EXPLORER_URL: "https://sepolia.starkscan.co",
      DISABLED: !0
    }
  }
};
var R = /* @__PURE__ */ ((E) => (E.LOG_DEPOSIT = "LogDeposit", E.LOG_WITHDRAWAL = "LogWithdrawal", E.LOG_MESSAGE_TO_L2 = "LogMessageToL2", E))(R || {});
const v = {
  L1: R,
  L2: null
};
var N = /* @__PURE__ */ ((E) => (E[E.UNSUPPORTED_BROWSER = 0] = "UNSUPPORTED_BROWSER", E[E.UNSUPPORTED_CHAIN_ID = 1] = "UNSUPPORTED_CHAIN_ID", E))(N || {}), r = /* @__PURE__ */ ((E) => (E.L1 = "Ethereum", E.L2 = "Starknet", E))(r || {});
const V = (E) => E === "Ethereum", l = (E) => E === "Starknet";
var t = /* @__PURE__ */ ((E) => (E.HANDLE_DEPOSIT = "handle_deposit", E))(t || {}), D = /* @__PURE__ */ ((E) => (E.INVOKE = "115923154332517", E.L1_HANDLER = "510926345461491391292786", E))(D || {}), _ = /* @__PURE__ */ ((E) => (E.NOT_RECEIVED = "NOT_RECEIVED", E.RECEIVED = "RECEIVED", E.ACCEPTED_ON_L2 = "ACCEPTED_ON_L2", E.ACCEPTED_ON_L1 = "ACCEPTED_ON_L1", E.REJECTED = "REJECTED", E.REVERTED = "REVERTED", E))(_ || {});
const f = {
  REJECTED: "Invalid transaction",
  NOT_RECEIVED: "Waiting to be accepted on L2",
  RECEIVED: "Waiting to be accepted on L2",
  ACCEPTED_ON_L2: "Accepted on L2",
  ACCEPTED_ON_L1: "Proved and accepted on L1",
  REVERTED: "Transaction reverted"
}, o = [
  "NOT_RECEIVED",
  "RECEIVED"
  /* RECEIVED */
], L = [
  "REJECTED",
  "ACCEPTED_ON_L1"
  /* ACCEPTED_ON_L1 */
], c = [
  "ACCEPTED_ON_L2"
  /* ACCEPTED_ON_L2 */
];
var I = /* @__PURE__ */ ((E) => (E[E.NOT_RECEIVED = 0] = "NOT_RECEIVED", E[E.RECEIVED = 1] = "RECEIVED", E[E.PENDING = 2] = "PENDING", E[E.ACCEPTED_ON_L2 = 3] = "ACCEPTED_ON_L2", E[E.ACCEPTED_ON_L1 = 4] = "ACCEPTED_ON_L1", E[E.REJECTED = 5] = "REJECTED", E))(I || {});
const g = (E) => o.includes(E), u = (E) => c.includes(E), h = (E) => L.includes(E), m = (E) => E === "REJECTED", k = (E) => E === "ACCEPTED_ON_L1";
var A = /* @__PURE__ */ ((E) => (E.CONNECTION_REJECTED_ERROR = "ConnectionRejectedError", E.CHAIN_UNSUPPORTED_ERROR = "ChainUnsupportedError", E))(A || {}), s = /* @__PURE__ */ ((E) => (E.DISCONNECTED = "disconnected", E.CONNECTING = "connecting", E.CONNECTED = "connected", E.ERROR = "error", E))(s || {});
const H = (E) => E === "connected", a = (E) => E === "disconnected", x = (E) => E === "connecting", J = (E) => E === "error";
var P = /* @__PURE__ */ ((E) => (E.EDGE = "Edge", E.OPERA = "Opera", E.CHROME = "Chrome", E.FIREFOX = "Firefox", E.SAFARI = "Safari", E.INTERNET_EXPLORER = "Internet Explorer", E))(P || {}), C = /* @__PURE__ */ ((E) => (E.METAMASK = "metamask", E.COINBASE = "coinbase", E))(C || {}), O = /* @__PURE__ */ ((E) => (E.GSW = "gsw", E))(O || {});
const X = {
  L1: C,
  L2: O
};
export {
  P as BrowserType,
  U as ChainInfo,
  i as ChainType,
  e as ChainTypeL1,
  n as ChainTypeL2,
  v as EventName,
  R as EventNameL1,
  N as LoginErrorType,
  r as NetworkType,
  t as SelectorName,
  L as TransactionCompletedStatuses,
  c as TransactionConsumedStatuses,
  D as TransactionHashPrefix,
  o as TransactionPendingStatuses,
  _ as TransactionStatus,
  f as TransactionStatusFriendlyMessage,
  I as TransactionStatusStep,
  A as WalletErrorType,
  X as WalletId,
  C as WalletIdL1,
  O as WalletIdL2,
  s as WalletStatus,
  h as isCompleted,
  H as isConnected,
  x as isConnecting,
  u as isConsumed,
  a as isDisconnected,
  J as isError,
  G as isL1Mainnet,
  V as isL1Network,
  T as isL1Testnet,
  M as isL2Mainnet,
  l as isL2Network,
  d as isL2Testnet,
  k as isOnChain,
  g as isPending,
  m as isRejected
};
